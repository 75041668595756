import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import tw from 'twin.macro'
import Container from './Container';
import SlideIndicators from './SlideIndicators';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { H3 } from './Headings';

import useSlides from '../hooks/useSlides'
import { useSwipeable} from 'react-swipeable'

const useGraphics = () => {

    const data = useStaticQuery(graphql`{
        houseGraphic : allFile(filter: {relativePath: {eq: "house-1-hi-res.png"}}) {
            nodes {
                childImageSharp {
                    fixed(width: 620, height: 427) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        stakeGraphic : allFile(filter: {relativePath: {eq: "stake-alone.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 212, height: 294) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        stakeWithRaysGraphic : allFile(filter: {relativePath: {eq: "house-2.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 212, height: 294) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        zonesGraphic : allFile(filter: {relativePath: {eq: "sentricon-zones.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 620, height: 427) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        protectedHouseGraphic : allFile(filter: {relativePath: {eq: "house-3.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 620, height: 427) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    }`);

    const houseGraphic = data.houseGraphic.nodes[0].childImageSharp;
    const stakeGraphic = data.stakeGraphic.nodes[0].childImageSharp;
    const stakeStatic = data.stakeGraphic.nodes[0].publicURL;
    const stakeWithRaysGraphic = data.stakeWithRaysGraphic.nodes[0].childImageSharp;
    const stakeWithRaysStatic= data.stakeWithRaysGraphic.nodes[0].publicURL;
    const zonesGraphic = data.zonesGraphic.nodes[0].childImageSharp;
    const zonesStatic = data.zonesGraphic.nodes[0].publicURL;



    const protectedHouseGraphic = data.zonesGraphic.nodes[0].childImageSharp;
    const protectedHouseStatic = data.zonesGraphic.nodes[0].publicURL;

    return ({
        houseGraphic,
        stakeGraphic,
        stakeStatic,
        stakeWithRaysGraphic,
        stakeWithRaysStatic,
        zonesGraphic,
        zonesStatic,
        protectedHouseGraphic,
        protectedHouseStatic,
    });
}


const Slide1Content = () => (
    <div tw="z-20">
        <H3>How To Identify Termites</H3>
        <p tw="text-gray-700 text-lg leading-relaxed mb-3">It takes a professional to tell if termites are lurking in your home.  It's easy to mistake a flying ant for a termite because they both have wings during the reproductive stage.  To eliminate the guesswork, contyact Cook's for a free inspection.</p>
        <p tw="text-gray-700 text-lg leading-relaxed">Each year, termites cause damage to more than hald a million homes in the United States.  Early detection and prevention could save you thousands of dollars.</p>
    </div>
)

const Slide2Content = () => (
    <div tw="z-20">
        <H3>How We Treat: Sentricon<sup>®</sup></H3>
        <p tw="text-gray-700 text-lg leading-relaxed">Compared to gallons of liquid chemical treatments, Cook's uses a more environmentally-friendly approach with the Sentricon® System with Always Active™ technology, winner of the Presidential Green Chemistry Challenge Award.  The Sentricon® System with Always Active™ technology uses bait stations placed arround the home.  This eliminates the need for liquid chemical treatments to the home's foundation.</p>
    </div>
)
const Slide3Content = () => (
    <div tw="z-20">
        <H3>Defending your Home...</H3>
        <p tw="text-gray-700 text-lg leading-relaxed mb-3">Stations are placed around your home, and with our Always Active™ technology, the Recruit HD termite bait is available to begin working immediately.</p>
        <H3>...By Eliminating the Colony</H3>
        <p tw="text-gray-700 text-lg leading-relaxed">The active ingredient, noviflumuron, gets shared within the colony and prevents termites from maturing through molting.  Without molting, the colony can no longer survive.</p>

    </div>
)


const SenticonHouse = ({ slides }) => {

    const {
        houseGraphic,
        stakeGraphic,
        stakeStatic,
        stakeWithRaysGraphic,
        stakeWithRaysStatic,
        zonesGraphic,
        zonesStatic,
        protectedHouseGraphic,
        protectedHouseStatic
    } = useGraphics();

    const [currentSlide, slideControls] = useSlides(3);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            slideControls.nextSlide();
            // console.log(eventData);
        },
        onSwipedRight: (eventData) => {
            slideControls.prevSlide();
            // console.log(eventData)
        },
    });


    return (
        <>
        <section {...swipeHandlers} tw="relative w-full bg-yellow-600 py-10 overflow-x-hidden">
            {/* Make the top part of the section white; */}
            <div tw="hidden lg:(flex absolute w-full top-0 left-0 h-24 z-0 bg-white)" />

            <Container tw="md:(grid gap-8) lg:(grid-cols-2) z-10 px-5">
                <AnimateSharedLayout>
                    <motion.div 
                        initial={{opacity: 0}} 
                        animate={{opacity: 1}} 
                        exit={{opacity: 0}} 
                        layoutId="text-content" 
                        tw="z-10 flex justify-center content-center flex-col md:(py-10)"
                    >   

                        <AnimatePresence>
                            {Array.isArray(slides) && slides.map(({ content }, slideIndex) => (
                                <>
                                    {(currentSlide === (slideIndex + 1)) && (
                                        <motion.div
                                            exit={tw`opacity-0 hidden`}
                                        >

                                            <div tw="prose"
                                                
                                                dangerouslySetInnerHTML={{ __html: content }} 
                                            />
                                        </motion.div>
                                    )}
                                </>
                            ))}
                        </AnimatePresence>
                        
                        <div tw="sm:(hidden)">
                            <SlideIndicators currentSlide={currentSlide} setSlide={slideControls.setSlide} totalSlides={slideControls.totalSlides} />
                        </div>
                    </motion.div>
                </AnimateSharedLayout>
                
                
                {/*  */}
                <div
                    css={[
                        tw`z-10 relative mx-auto`,
                        // Handle mobile size
                        `transform: scale(0.6);
                        /* center the house */
                        left: calc((100% - 640px) / 4);

                        @media (min-width: 640px){
                            transform: scale(1);
                            left:0;
                        }`,
                    ]}
                >
                    <AnimatePresence>
                        {(currentSlide === 2) && (
                            <>
                                <motion.img
                                    initial={{opacity: 0, y: -100, position: 'absolute', zIndex: 50, x: 32}}
                                    animate={{opacity: 1, y: 100}}
                                    exit={{opacity: 0}}
                                    src={stakeStatic} 
                                />
                                <motion.img
                                    initial={{opacity: 0, y: 100, position: 'absolute', zIndex: 50, x: 30}}
                                    animate={{opacity: 1, }}
                                    transition={{delay: .3 }}
                                    exit={{opacity: 0}}
                                    src={stakeWithRaysStatic} 
                                />
                            </>
                        )}
                        
                    </AnimatePresence>
                    <AnimatePresence>
                        {(currentSlide === 3) && (
                            <>
                                <motion.img 
                                    initial={{
                                        opacity: 0, 
                                        zIndex: 40, 
                                        y: 0, 
                                        x: 0, 
                                        position: 'absolute', 
                                        top: 0, 
                                        bottom: 0, 
                                        left: 0, 
                                        right: 0,
                                        minWidth: `620px`,
                                    }}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    src={zonesStatic}
                                />
                            </>
                        )}
                    </AnimatePresence>
                    
                    <Img 
                        css={[
                            tw`z-40 absolute transition`,
                            (currentSlide === 2) && `
                                filter: sepia(75%) hue-rotate(7deg) opacity(45%); 
                                ${tw`opacity-25`}
                            `,
                        ]} 
                        fixed={houseGraphic.fixed} 
                    />

                </div>
            </Container>

            <div 
                css={[
                    tw`relative hidden sm:(flex) justify-center xl:(absolute)`,
                    `@media (min-width: 1280px) { 
                         right: 2rem; 
                         top: calc(50% - 2rem); 
                    }

                    `
                ]}
            >
                <SlideIndicators currentSlide={currentSlide} setSlide={slideControls.setSlide} totalSlides={slideControls.totalSlides} />
            </div>
            
        </section>
        
        </>
    )

}

export default SenticonHouse;